.App {
  text-align: center;
  max-width: 1024px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}
.container {
  background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, 
  rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100% );
  background-size: 150% auto;
}
.battleslogo {
  transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  fill: #111 !important;
  filter: drop-shadow(0 0 10px orange) drop-shadow(0 0 15px red);
}
.battleslogo:hover {
  opacity: 1;
  transform: rotate(10deg) scale(1.2);
}

.battleslogotext {
  opacity: .5;
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
}

.battleslogotext:hover {
  opacity: 1;
  transform: scale(1.05);
  filter: drop-shadow(0 0 5px orange) drop-shadow(0 0 10px red);
}


